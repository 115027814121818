import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { UIProvider } from "./components/UIContext/UIContext";
import "./index.css";
import reactQueryClient from "./lib/reactQuery";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <UIProvider>
      <BrowserRouter>
        <QueryClientProvider client={reactQueryClient}>
          <Routes>
            <Route path="/payment" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>

      <ToastContainer hideProgressBar position="bottom-right" />
    </UIProvider>
  );
}

export default App;
