import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { API_URI, API_VERSION } from "../../config/constants";

export interface Services {
  name: string;
  key: string;
  image_url: string;
  methods: string[]; // Changed this to an array of strings
}

export default function PaymentCard({ selected, setSelected }: any) {
  const [services, setServices] = React.useState<Services[]>([]);

  console.log("selected is", selected);

  React.useEffect(() => {
    async function fetchServices() {
      try {
        const response = await fetch(
          API_URI + API_VERSION + "/payment/services",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        const result = await response.json();
        setServices(result.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchServices();
  }, []);

  return (
    <div className="w-full">
      <div className="mx-auto w-full">
        <RadioGroup
          value={selected}
          onChange={setSelected}
          aria-label="Server size "
          className=""
        >
          {services.map((service: Services) => (
            <Radio
              key={service.key} // Changed key to use service.key
              value={service}
              className="group grid grid-cols-12 relative cursor-pointer"
            >
              <div
                className={clsx(
                  "grid col-span-2 rounded-lg overflow-hidden relative",
                  {
                    "gradient-border rounded-none overflow-visible":
                      selected?.key === service?.key,
                  },
                )}
              >
                <img
                  src={service.image_url}
                  alt={service.name || "payment logo"}
                  className=""
                />
                {selected?.key === service?.key && (
                  <CheckCircleIcon className="w-8 h-8 absolute top-0 right-0 fill-white/90" />
                )}
              </div>
            </Radio>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
