import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../anh/ui/components/Button";
import Container from "../anh/ui/components/Container";
import Grid from "../anh/ui/components/Grid";
import TextField from "../anh/ui/components/TextField";
import Typography from "../anh/ui/components/Typography";
import logo from "../assets/axra-pay-logo.png";
import MerchantDetails from "../components/MerchantDetails";
import PaymentCard from "../components/PaymentCard";
import { Services } from "../components/PaymentCard/PaymentCard";
import { API_URI, API_VERSION } from "../config/constants";

const Home = () => {
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [selected, setSelected] = React.useState<Services | null>(null);

  const [order, setOrder] = React.useState<any>(null);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
  });
  const location = useLocation();
  const serachValue = location.search.slice(18, location.search.length);

  const navigate = useNavigate();

  const handleSubmitCustomerInfo = async () => {
    setIsSubmit(true);
    const data = {
      channel: selected?.key,
      customer_email: formData.email,
      customer_name: formData.name,
      merchOrderId: order?.merchOrderId,
      method: selected?.methods[1],
      payMethod: selected?.name,
      referenceNo: order?.reference_number,
      status: order?.status,
      checkSum: order.merchant_checkSum,
    };
    try {
      if (
        selected?.key !== "" &&
        formData.name !== "" &&
        formData.email !== ""
      ) {
        console.log(data);
        const response = await fetch(
          API_URI + API_VERSION + "/payment/request",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          },
        );
        const result = await response.json();
        if (result.data.id) {
          window.open(
            `https://api.pay.axra.app/payment?reference_number=${result.data.reference_number}&&checkSum=${result.data.merchant_checkSum}`,
            "_blank",
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(
          API_URI + API_VERSION + `/payment/orders/${serachValue}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        const result = await response.json();
        console.log(result);
        if (result.data && result?.data?.status === "Pending") {
          setOrder(result.data);
        } else {
          navigate("/");
          setOrder(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [navigate, serachValue]);
  return (
    <div className="flex flex-col my-5 px-4 justify-center items-center">
      <Container
        maxWidth="md"
        className="shadow p-5 border-gray-50 border rounded-lg"
      >
        <div className="flex flex-row items-center justify-center">
          <img
            src={logo as string}
            className="sm:w-32 object-contain sm:h-32 w-24 h-24 text-center"
            alt={"axra pay logo"}
          />
        </div>
        <MerchantDetails order={order} />

        <Container maxWidth="md" className="mt-6">
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:my-5 my-2"
          >
            Customer Details
          </Typography>

          <Grid container className="gap-2 sm:gap-4">
            <Grid item className="col-span-12">
              <Typography
                component="p"
                className="text-gray-500 mb-1 sm:text-base text-sm"
              >
                Name
              </Typography>
              <TextField
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                error={formData.name === "" && isSubmit ? true : false}
                helperText={
                  formData.name === "" && isSubmit
                    ? "Name is required field!"
                    : ""
                }
                type="text"
                inputSize={"small"}
                fullWidth
                placeholder="Name"
              />
            </Grid>
            <Grid item className="col-span-12">
              <Typography
                component="p"
                className="text-gray-500 mb-1 sm:text-base text-sm"
              >
                Email
              </Typography>
              <TextField
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                error={formData.email === "" && isSubmit ? true : false}
                helperText={
                  formData.email === "" && isSubmit
                    ? "Email is required field!"
                    : ""
                }
                type="email"
                inputSize={"small"}
                fullWidth
                placeholder="Email"
              />
            </Grid>
          </Grid>
        </Container>

        <div className="mt-6">
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:my-5 my-2"
          >
            Payment Methods
          </Typography>

          <PaymentCard selected={selected} setSelected={setSelected} />

          <Grid container className="gap-6">
            <Grid item className="col-span-6">
              <Button
                onClick={handleSubmitCustomerInfo}
                fullWidth
                type="button"
                size="large"
                className="bg-purple-600 mt-10 text-white"
              >
                Submit
              </Button>
            </Grid>
            <Grid item className="col-span-6">
              <Button
                onClick={() => {}}
                fullWidth
                size="large"
                className="bg-red-600 mt-10 text-white"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Home;
