import Container from "../../anh/ui/components/Container";
import Grid from "../../anh/ui/components/Grid";
import Typography from "../../anh/ui/components/Typography";

const MerchantDetails = ({ order }: any) => {
  return (
    <Container maxWidth="md" className="mt-8">
      <Grid container className="gap-2 sm:gap-4">
        <Grid item className="col-span-6">
          <Typography
            component="p"
            className="text-gray-500 sm:text-base text-sm"
          >
            Merchant Name
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:text-base text-sm"
          >
            MULA
          </Typography>
        </Grid>
        <Grid item className="col-span-6">
          <Typography
            component="p"
            className="text-gray-500 sm:text-base text-sm"
          >
            Merchant Order ID
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:text-base text-sm"
          >
            {order?.merchOrderId}
          </Typography>
        </Grid>
        <Grid item className="col-span-6">
          <Typography
            component="p"
            className="text-gray-500 sm:text-base text-sm"
          >
            Amount
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:text-base text-sm"
          >
            {order?.amount}
            {order?.currenyCode === "104" ? " MMK" : ""}
          </Typography>
        </Grid>
        <Grid item className="col-span-6">
          <Typography
            component="p"
            className="text-gray-500 sm:text-base text-sm"
          >
            Reference Number
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:text-base text-sm"
          >
            {order?.reference_number}
          </Typography>
        </Grid>
        {/* <Grid item className="col-span-6">
          <Typography
            component="p"
            className="text-gray-500 sm:text-base text-sm"
          >
            Product ID
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:text-base text-sm"
          >
            {order?.productId ? order?.productId : "#"}
          </Typography>
        </Grid>
        <Grid item className="col-span-6">
          <Typography
            component="p"
            className="text-gray-500 sm:text-base text-sm"
          >
            Description
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            className="text-gray-800 sm:text-base text-sm"
          >
            {order?.description ? order?.description : "#"}
          </Typography>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default MerchantDetails;
