export const API_URI = process.env.API_URI || "https://api.pay.axra.app/";
export const API_VERSION = process.env.API_VERSION || "v1";

export const QUERY_KEYS = {
  LINKS: "LINKS",
  ME: "ME",
};

export const DEMO_ADMINS = [
  {
    username: "admin",
    password: "password",
  },
];

export const DEMO_USERS = [
  {
    username: "user",
    password: "password",
  },
];
